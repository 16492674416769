import "./App.css";
import adonmo from "./adonmo.png";
import zomato from "./zomato.avif";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useState } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.adonmo.com/avail/api"
    : "http://localhost:5000/avail/api";

function App() {
  const [done, setDone] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const invalidEnv = !params.has("cid") || !params.has("sid");
  if (invalidEnv) {
    return <ErrorMessage error="Invalid state." />;
  }

  const onSubmit = (cid, sid, data) => {
    setSubmitting(true);
    fetch(BASE_URL + "/ev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ e: "FORM_SUBMIT", cid, sid, data }),
    })
      .then((res) => {
        res.json();
        setDone(true);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <QueryClientProvider client={queryClient}>
      {done ? (
        <DoneMessage />
      ) : (
        <ZomatoForm
          params={params}
          submitting={submitting}
          onSubmit={onSubmit}
        />
      )}
    </QueryClientProvider>
  );
}

function DoneMessage() {
  return (
    <Message
      title="✓ Done!"
      description="Voucher will be sent to your phone soon"
    />
  );
}

function ErrorMessage({ error }) {
  return <Message title="Error!" description={error} />;
}

function Message({ title, description }) {
  return (
    <div className="App bold">
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
    </div>
  );
}

function ZomatoForm({ params, submitting, onSubmit }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const cid = params.get("cid");
  const sid = params.get("sid");
  const { isLoading, error } = useQuery("scan_event", () =>
    fetch(BASE_URL + "/ev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ e: "SCAN", cid, sid }),
    }).then((res) => res.json())
  );
  if (error) {
    return <ErrorMessage error="Server error. Try later. You can also mail us at ask@adonmo.com." />
  }
  const disabled = isLoading || submitting;

  return (
    <div className="App">
      <img src={zomato} className="App-logo" alt="logo" />
      <h1 className="title">Zomato Pro Plus @ ₹85/-</h1>
      <h2 className="description">Fill the form below to avail the offer</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(cid, sid, { name, phone });
        }}
      >
        <input
          disabled={disabled}
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
          autoFocus
        />
        <input
          disabled={disabled}
          type="tel"
          name="phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="+91 XXXXX XXXXX"
        />
        <input disabled={disabled} type="hidden" name="cid" value={cid} />
        <input disabled={disabled} type="hidden" name="sid" value={sid} />
        <input disabled={disabled} type="submit" value="Submit" />
      </form>
      <img src={adonmo} className="App-logo" alt="logo" />
    </div>
  );
}

export default App;
